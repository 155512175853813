h1,
h2,
h3,
h4,
h5,
h6 {
  color: #0482f0;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.site-heading h2 {
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.site-heading h2 span {
  color: #0482f0;
}

.site-heading h4 {
  display: inline-block;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.site-heading h4::before {
  background: #0482f0 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  margin-left: -25px;
  position: absolute;
  width: 50px;
}

.site-heading {
  margin-bottom: 60px;
  overflow: hidden;
  margin-top: -5px;
}

.heading {
  margin-top: 0px;
}

.separator {
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.separator:after,
.separator:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 2px;
  background: #0482f0;
  top: 50%;
}

.separator i {
  color: #0482f0;
}

.separator:after {
  right: 140%;
}

.separator:before {
  left: 140%;
}

.amazing_feature {
  padding-top: 80px;
  padding-bottom: 10px;
}

@media only screen and (max-width:467px) {
  .amazing_feature {
    padding-top: 0px;
    padding-bottom: 80px !important;
    ;
  }
}

.single_feature {
  background: white none repeat scroll 0 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  opacity: 1;
  z-index: 10;
  padding: 30px 30px;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;

}

.single_feature:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0482f0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.single_feature:hover,
.single_feature:focus,
.single_feature:active {
  color: white !important;
}

.single_feature:hover:before,
.single_feature:focus:before,
.single_feature:active:before {
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}

.single_feature i {
  border: 1px solid #0482f0;
  border-radius: 50%;
  color: #0482f0;
  float: left;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  margin-right: 15px;
  position: relative;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 60px;
  z-index: 3;
  margin-top: 25px;
}

.single_feature:hover i {
  background: #fff;
  border: 1px solid #fff;
  color: #0482f0;
}


.single_feature:hover p {

  color: #fff;
}

.single_feature h3 {
  text-transform: uppercase;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  overflow: hidden;
}

.single_feature:hover h3 {
  color: #fff !important;
}

.single_feature span {
  border-bottom: 1px dashed #ccc;
  display: block;
  margin: 15px auto 10px;
  width: 80px;
}

.single_feature p {
  margin-bottom: 0;
  overflow: hidden;
  font-size: 14px;
  color: #000;
}

.single_feature p:hover {
  color: #FFF !important;
}