.header-top{
    background:  #0482f0 ;
    padding: 0px;
    padding: 0!important;
    margin: 0!important;
}
@media only screen and (max-width: 600px) {
    .header-top{
     display: none !important;
    }
  }