
@import url('https://fonts.googleapis.com/css2?family=K2D:wght@200&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white !important;
}

p{
  font-family: 'Open Sans', sans-serif !important;

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New' ,
    monospace !important;
}
.section-title::after {
  background: #0482f0 none repeat scroll 0 0 !important;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
}

.c-details-featue > span i {color: #0482f0  !important; font-size: 26px; padding-right: 10px; position: relative; top: 3px;}


.event-date {
  color: #0482f0 !important ;
  display: block;
  float: left;
  font-size: 30px;
  font-weight: 700;
  line-height: 22px;
  margin-right: 10px;
  text-align: center;
  width: 44px;
}

.event-title > h4 {font-weight: 600; line-height: 15px; padding-bottom: 13px;}
.event-title > span {color: #a1a1a1; display: block; line-height: 13px;}
.event-title > span i {color: #0482f0 !important ; font-size: 15px; padding-right: 9px;}
.scroll-to-top {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
}