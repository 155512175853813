.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    
   
}

  .spinner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top-color: #0482f0; /* Change color here */
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }