.objective {
    padding: 20px 10px 100px 10px;
    background-color: #FFF;
}

.object {
    background-color: #f3f8fa;
    padding: 50px 10px 100px 10px;
}

.objective p {
    line-height: 40px;
}

.engineering-image-wrapper img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: center;
    opacity: 110;
    border-radius: 10px;
    aspect-ratio: 1/1;
}

.question-form input::placeholder {
    color: #0482f0 !important;

}

.question-form input {
    border: 1px solid #0482f0 !important;
    color: #0482f0 !important;
    border-radius: 5px;
    padding: 10px;
    background-color: white;
}