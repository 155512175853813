.sliderimg {
    
    /* Add a background color to see the empty space */
}

.sliderimg img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

@media screen and (max-width: 767px) {
    .sliderimg {
        min-height: 400px;
    }
}

@media screen and (max-width: 467px) {
    .sliderimg {
        min-height: 300px;
    }
}