

/* Custom css */
.cbck {
  position: relative;
background-color: #FFF;

}


.team-header h3 {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 400;
}

.team-header h1 {
  font-size: 60px;
  color:#0482f0;
  text-transform: uppercase;
  font-size: 400;
  font-weight: 700;
  margin-top: 0;
}
.team-header i {
  position: relative;
  font-size: 50px;
  color:#0482f0;
}
.team-header i:before {
  content:'';
  height: 1px;
  width: 50px;
  position: absolute;
  top: 0;
  left: 0;
  background:#0482f0;
}


.team-header i:after {
  content:'';
  height: 1px;
  width: 50px;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 20px;
  background:#0482f0;
}

.team-img {
  margin: 100px 0;
  position: relative;
}



/* .img-profile:after {
  content:'';
  position: absolute;
  left:0;
  bottom: -10px;
  height: 1px;
  width: 20%;
  background:#0482f0;
  transition: .3s all;
}

.img-profile:before {
  content:'';
  position: absolute;
  left:0;
  bottom: -10px;
  height: 30%;
  width: 1px;
  background:#0482f0;
  transition: .3s all;
}


.img-profile span:after {
  content:'';
  position: absolute;
  right:0;
  top: -10px;
  height: 1px;
  width: 30%;
  background:#0482f0;
  transition: .3s all;
}

.img-profile span:before {
  content:'';
  position: absolute;
  right:0;
  top: -10px;
  height: 40%;
  width: 1px;
  background:#0482f0;
  transition: .3s all;
} */

.img-profile:hover.img-profile:after {
  width: 100%;
} 

.img-profile:hover.img-profile:before {
  height: 110%;
} 


.img-profile:hover.img-profile span:after {
  width: 100%;
} 

.img-profile:hover.img-profile span:before {
  height: 110%;
}

.img-profile img{
  position: relative;
  height: 100%;
  width: 100%;
 
}

.team-member {
  margin: 60px 0;
}

.team-member:after {
  content: '';
  position: absolute;
  width: 1px;

  top: 30px;
  left: -40px;
}

.team-member h2 {
  font-size: 25px;
  font-weight: 900;
  line-height: 1;
  color:black;
} 

.header-hr {
  width: 30%;
  height: 1px;
  background:#0482f0;
  display: inline-block;
}

.team-member h4 {
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  color:black;
}

.team-member ul.social-icon {
  list-style-type: none;
  margin: 30px 0;

  
}

.team-member ul.social-icon li {
  display: inline-block;
}

.team-member ul.social-icon li a {
  border: 1px solid #cdcdcd;
  width: 30px;
  height: 30px;
  border-radius:50%;
  margin-right: 10px;
  font-size: 14px;
  line-height: 30px;
  display: block;
  text-align: center;
  transition: .3s all;
}

.team-member ul.social-icon li a:hover {
  border-color: #0482f0;
 background-color: #0482f0;
}

.team-member p {
  font-size: 15px !important;
  font-weight: 400 !important;
}

a.view-btn {
  border: 2px solid #0482f0;
  font-weight: 700;
  font-size: 14px;
  color: #000;
  text-transform: uppercase;
  padding: 5px;
  position: relative;
  z-index: 1;
}

a.view-btn:hover {
  text-decoration: none;
  color: #fff
}
a.view-btn:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: #0482f0;
  transition: all 0.3s ease;
}

a.view-btn:hover:after {
  top: 0;
  height: 100%;
}

.hr-2 {
  width: 30px;
  height: 3px;
  background: #0482f0;
  position: relative;
  margin: 0;
  margin-bottom: 40px;
  margin-top: 20px;
}

.hr-2:after {
  position: absolute;
  content:'';
  top: 0;
  right: -100px;
  width: 30px;
  height: 3px;
  background:#0482f0;
  margin-right: 20px;
}


.hr-2:before {
  position: absolute;
  content:'';
  top: 0;
  left: 20px;
  width: 30px;
  height: 4px;
  background:#0482f0;
  margin-left: 20px;
}

.message-content {
  
  box-shadow: 0 2px 10px rgba(74, 73, 73, 0.1);
  padding: 20px;
}

@media screen and (max-width: 800px) {
  .team-member {
    text-align: center;
  }
  
  .team-img {
    margin: 50px 0;
  }
}