/* .banner-section {
    background:  #75d120 url(http://www.webcoderskull.com/img/graphic-abstract.jpg) no-repeat top right fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 300px;
    position: relative;
    background-blend-mode: multiply;
    margin-top: -60px!important;
}
.content-inner {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70%;     
    transform: translate(-50%, -50%);
  text-align:center;
}
.banner-section .content-inner .hero-text{
    color: white;
    font-size: 2rem;
    padding: 0 .5em;
    line-height: 1.25em;
    font-family: "freight-big-pro", sans-serif;
    display: block;
     font-weight: 300;   
}
.banner-section .content-inner p{
   color:#fff;
  font-size: 2rem;
} */



.banner_area {
    background: url('./ban.jpg') no-repeat  center center  fixed;
    background-size: cover;
    position: relative;
    z-index: 3;
    
      min-height: 200px;
    padding: 100px 0px 80px 0px;
  }
  
  .banner_area:before {
    content: "";
    background:  rgba(10, 0, 20, 0.6);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    
  }
  
  .banner_inner_text {
    text-align: left;
  }
  
  .banner_inner_text h4 {
    font-size: 35px;
    font-family: "lato";
    font-weight: bold;
    color: #fff;
    padding-bottom: 3px;
  }
  
  .banner_inner_text ul li {
    display: inline-block;
  }
  
  .banner_inner_text ul li a {
    font-size: 18px;
    color: #fff;
    font-family: "lato";
    padding-right: 15px;
    position: relative;
    -webkit-transition: all 400ms linear 0s;
    -o-transition: all 400ms linear 0s;
    transition: all 400ms linear 0s;
  }
  
  .banner_inner_text ul li a:before {
    content: "/";
    position: absolute;
    right: 2px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #fff;
  }
  
  .banner_inner_text ul li:last-child a:before {
    display: none;
    font-size: 25px;
  }
  
  .banner_inner_text ul li:hover a, .banner_inner_text ul li.active a {
    color: #09a4fd;
    
  }
  @media screen and (max-width:467px){

    .banner_area {
     height: 100px;
    
 
    }

  }