@media only screen and (max-width: 467px) {
    .padd {
        padding-top: 20px;
        padding-bottom: 20;
    }
}

.about-fun-area {
    position: relative;
    /* Ensure proper stacking of elements */
    background: url("./ab.jpg") center / cover no-repeat fixed;
    /* Set the background image */
    overflow: hidden;
    /* Hide overflow to prevent content from protruding */
    background-color: rgba(0, 0, 0, 0.5);
    /* Set the background color with opacity */

    padding-bottom: 100px;
    margin-bottom: 50px;
}

.about-fun-area::before {
    content: "";
    /* Create a pseudo-element to apply the blur effect */
    position: absolute;
    /* Position it absolutely relative to the container */
    top: 0;
    /* Align it to the top of the container */
    left: 0;
    /* Align it to the left of the container */
    width: 100%;
    /* Take up the full width of the container */
    height: 100%;
    /* Take up the full height of the container */
    background: inherit;
    /* Inherit the background image from the container */
    filter: blur(10px);
    /* Apply a blur effect to the background */
    background-color: rgba(0, 0, 0, 0.5);
    /* Set the background color for the pseudo-element with opacity */
}

.about-fun-area::after {

    content: "" !important;
    background: rgba(0, 0, 0, 0.5) !important;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    right: 0;

}

.about-containers {
    position: relative;
    /* Ensure proper stacking of elements */
    z-index: 2;
    /* Place content above the blurred background */
    color: white;
    /* Set text color */
    padding: 20px;
    /* Add padding to the content for spacing */
}

.c-details-feature {
    background: white;
    /* Background color */
    padding: 30px;
    /* Padding for content */
    border-radius: 5px;
    /* Rounded corners */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    /* Box shadow for depth */
    transition: transform 0.3s ease;
    /* Smooth transition effect */
    cursor: pointer;
    /* Cursor on hover */
    text-align: center;
    /* Center text */
    margin-top: 10px;
}

.c-details-feature i {
    font-size: 40px;
    font-weight: bold;
    color: #0482f0;

}

.c-details-feature h4 {
    display: block;
    font-size: 16px !important;
    padding: 5px;
    /* Font size */
    color: black;
    /* Text color */
    font-weight: bold;
    /* Bold font weight */
}



.history-area {
    padding-top: 100px;
    padding-bottom: 100px;
}

.who-button {
    display: inline-block;
    padding: 8px 30px;
    background-color: #0482f0;
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;

    border-radius: 1px;
    transition: all 0.3s ease;
}

.who-button:hover {
    background-color: #0369d9;
    color: white;
}

.img-fluid {
    aspect-ratio: 1/1;





}

.aboutpage-content {
    padding: 50px;
}
.about-page-details-img img{
    width: 100%;
    height: 500px;
    object-fit: fill;
    object-position: center;
    border-radius: 10px;
    aspect-ratio: 1/1;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2);
}

.about-page-text {
    padding: 10px;
  

}
@media screen and (max-width: 467px) {
    .aboutpage-content {
        padding: 0px;

    }
    .about-page-text {
        
        padding:0;
        padding-top: 20px;
    
    }

}