.contact-card {
    /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */
    background-color: #fff;
    padding: 50px;
}

.contact-form-wrapper {
    padding: 50px;
    border-radius: 10px;
}

.sub-title {
    font-weight: bold;
    color: #0482f0;
    margin-bottom: 20px;
}

.form-group input {

    color: #0482f0 !important;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9fafb;
    height: 50px;
    width: 100%;
    border: 1px solid #b8babc;
    outline: none;
    margin-bottom: 25px;
    font-size: 16px;
    transition: 0.3s;


}

.form-group textarea {
    color: #0482f0 !important;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9fafb;
    width: 100%;
    border: 1px solid #b8babc;
    outline: none;
    margin-bottom: 15px;
    font-size: 16px;
    transition: 0.3s;

}

.form-group input::placeholder {

    font-size: 12px;
}

.form-group label {
    text-align: left;
    color: #3c98e9;
    font-size: 16px;
    margin-bottom: 10px;
    display: block;
    transition: 0.3s;
    font-weight: bold;
}

.form-control {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submit-btn {
    background-color: #0482f0;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0369d6;
    color: white;
}

.form-message {
    margin-top: 10px;
}

.contact-information {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
}

.contact-info-item {
    margin-bottom: 15px;
}

.contact-info-item i {
    margin-right: 10px;
}

.info-text {
    font-size: 16px;
    color: #000;
}

.contact-text h3 {
    font-weight: bold;
    font-size: 40px;
    color: black !important;
    max-width: 70%;
    text-align: "justify";
    line-height: "50px";


}

@media screen and (max-width: 467px) {
    .contact-card {
        padding: 10px;
    }

    .contact-text h3 {
        max-width: 100%;
    }

    .contact-form-wrapper {
        width: 100%;
        padding: 0;
    }


}