
.footer_area {
  background-image:  url("./footer.jpg");
  position: relative;
  background-size: cover;
  background-position: center;
  z-index: 1.2;
  position: relative;
  justify-content: center;
  align-items: center;
}

.footer_area:after {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.1); /* Background color with opacity */
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: -1;
}
.footer_area .footer_row {
  padding-top: 95px;
  padding-bottom: 20px;
}
.footer_area .footer_row .footer_about {
  padding-bottom: 50px;
}
.footer_area .footer_row .footer_about h2 {
  font: 400 18px "Oswald", sans-serif;
  text-transform: uppercase;
  color: #fff;
  padding-bottom: 25px;
}
.footer_area .footer_row .footer_about img {
  max-width: 100%;
}
.footer_area .footer_row .footer_about p {
  font: 400 14px/26px "Oswald", sans-serif;
  color: #fefefe;
  padding-top: 22px;
}
.footer_area .footer_row .footer_about .socail_icon {
  padding: 0;
  margin: 0;
  padding-top: 25px;

}
.footer_area .footer_row .footer_about .socail_icon li {
  display: inline-block;
  list-style: none;
  padding-left: 8px;
}
.footer_area .footer_row .footer_about .socail_icon li:first-child {
  padding: 0;
}
.footer_area .footer_row .footer_about .socail_icon li a {
  border: 2px solid  #fff;
  display: block;
  line-height: 26px;
  width: 30px;
  text-align: center;
  position: relative;
  z-index: 1;
}
.footer_area .footer_row .footer_about .socail_icon li a:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background:  #fff;
  z-index: -1;
 
  transform: scaleY(0);
  transform-origin: 50%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.footer_area .footer_row .footer_about .socail_icon li a i {
  font-size: 14px;
  color: #0482f0;
  display: inline-block;
  padding-top: 10px;
}

.footer_area .footer_row .footer_about .socail_icon li a i:hover {
  color:rgb(2, 124, 224)!important ;
}
.footer_area .footer_row .footer_about .socail_icon li a:hover:after, .footer_area .footer_row .footer_about .socail_icon li a:focus:after {
  transform: scaleY(1);
 
}
.footer_area .footer_row .footer_about .quick_link {
  padding: 0;
  margin: 0;
}
.footer_area .footer_row .footer_about .quick_link li {
  list-style: none;
}
.footer_area .footer_row .footer_about .quick_link li a {
  font: 400 14px/28px "Roboto", sans-serif;
  color: #fefefe;
  position: relative;
  padding-left: 30px;
  line-height: 35px;
}
.footer_area .footer_row .footer_about .quick_link li a i {
  font-size: 14px;
  color:  #0482f0;
  padding-right: 20px;

  position: absolute;
  left: 0;
  bottom: 0;
  
  transition: all 300ms linear 0s;
}
.footer_area .footer_row .footer_about .quick_link li a:hover, .footer_area .footer_row .footer_about .quick_link li a:focus {
  color:  #0482f0;
}
.footer_area .footer_row .footer_about .quick_link li a:hover i, .footer_area .footer_row .footer_about .quick_link li a:focus i {
  left: 8px;
}
.footer_area .footer_row .footer_about .twitter {
  font: 400 14px/28px "Roboto", sans-serif;
  color: #fefefe;
  display: block;
  padding-bottom: 15px;
}
.footer_area .footer_row .footer_about .twitter:hover, .footer_area .footer_row .footer_about .twitter:focus {
  color:  #0482f0;
}
.footer_area .footer_row .footer_about address p {
  font: 400 14px/28px "Roboto", sans-serif;
  color: #0482f0;
  padding: 0;
}
.footer_area .footer_row .footer_about address .my_address {
  padding: 0;
  margin: 0;
  padding-top: 15px;
}
.footer_area .footer_row .footer_about address .my_address li {
  list-style: none;
}
.footer_area .footer_row .footer_about address .my_address li a {
  font: 400 14px/28px "Roboto", sans-serif;
  color: #FFF;
}
.footer_area .footer_row .footer_about address .my_address li a i {
  color:  #0482f0;
  padding-right: 20px;
  font-size: 14px;
  line-height: 45px;
  display: inline-block;
}
.footer_area .footer_row .footer_about address .my_address li a:hover, .footer_area .footer_row .footer_about address .my_address li a:focus {
  color:  #FFF;
}
.footer_area .footer_row .footer_about address .my_address li span {
  display: inline-block;
  padding-left: 35px;
  margin-top: -30px;
}
.footer_area .copyright_area {
  background-image: rgba(100,20,0,0.2);
  font: 400 14px/100px "Roboto", sans-serif;
  color: #fefefe;
  text-align: center;
}

.footer_area .copyright_area a {
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  color:  #fff;
}
.footer_area .copyright_area a:hover, .footer_area .copyright_area a:focus {
  color: #fefefe;
  text-decoration: underline;
}



@media (max-width: 767px) {

  .footer_about.quick {
    padding-bottom: 50px !important;
  }

  .footer_area .footer_row {
    padding-bottom: 0;
  }


  .widget_area {
    padding-top: 60px;
  }
  .footer_area .footer_row .footer_about address .my_address li span {
    padding-left: 0;
  }
}



@media (max-width: 360px) {

  .footer_area .copyright_area {
    line-height: 26px;
    padding: 10px 0;

  }

  .footer_area .footer_row .footer_about address .my_address li span {
    padding-left: 30px;
  }

}

