.readmore-btn{

color: #0482f0;
font-weight: bold;
font-size: 15px;
text-transform: uppercase;

}
.readmore-btn:hover{
    color: #0482f0;
    text-decoration: none;
}

.destination-details{
    font-size: 15px;
    font-weight: bold;
    margin-top: 20px;
    padding: 20px;
}

.destination-details-image {
    width: 100%; /* Ensures the image takes up the full width of its container */
    max-width: 100%; /* Limits the image's maximum width to prevent overflow */
    height: auto; /* Allows the image to maintain its aspect ratio */
    box-shadow: 0 2px 10px rgba(74, 73, 73, 0.1);
    
  
  }
  
  .destination-details-image img {
    max-width: 100%;
    box-shadow: 0 2px 10px rgba(74, 73, 73, 0.1);
    display: block; /* Ensures the image is displayed as a block element */
    width: 100%; /* Ensures the image takes up the full width of its container */
    height: auto; /* Allows the image to maintain its aspect ratio */
  }


  