.objective {
    padding: 20px 10px 100px 10px;
    background-color: #FFF;
}

.object {
    background-color: #f3f8fa;
    padding: 50px 10px 100px 10px;
}

.objective p {
    line-height: 40px;
}

.objective-section {
    padding: 50px 0;
}

.objective-image {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    /* Add shadow effect */
    margin-bottom: 20px;
    object-fit: contain;
    object-position: center;
    aspect-ratio: 16/9;
}

.objective-content {
    padding: 20px;
    background-color: white;
    /* Light gray background */
    border-radius: 8px;
    margin-top: 20px;
    margin-bottom: 20px;
    border: 1px solid #dee2e6;
    /* Light border */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Subtle shadow effect */
    transition: all 0.3s ease;
    /* Smooth transition */
    cursor: pointer;
    position: relative;
    /* Position for pseudo-element */
}

.objective-content::before {
    content: '';
    /* Pseudo-element for decoration */
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(to right, #007bff, #00ff15);
    /* Gradient decoration */
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.objective-content:hover {
    transform: translateY(-3px);
    /* Move up slightly on hover */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
    /* Enhanced shadow on hover */
}

.objective-content p {
    color: black;
    /* Dark text color */
    font-size: 16px;
    /* Larger font size */
    line-height: 1.6;
    /* Improved line height */
}

.objective-content h3 {
    color: #007bff;
    /* Blue heading color */
    font-size: 20px;
    /* Larger font size for headings */
    font-weight: 600;
    /* Bold font weight */
    margin-bottom: 10px;
    /* Spacing between heading and content */
}


.objective-content:hover {
    transform: translateY(-3px);
    /* Add slight upward movement on hover */
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    /* Enhance shadow effect on hover */
}



.objective-list ul {
    padding: 20px;
 text-align: left;
    margin: 0;
    list-style:disc;
    color: black;
    padding: 0;
}

.objective-list li {
    margin-bottom: 15px;
    line-height: 1.6;
    /* Improve line spacing */
}

.section-title h3 {
    font-weight: bold;
    color: #0482f0;
    margin-bottom: 20px;
    /* Increase spacing */
}

.section-title p {
    color: #6c757d;
    font-size: 16px;
    /* Adjust font size */
    line-height: 1.6;
    /* Improve line spacing */
}